import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'

import styles from './work-feature.module.css'

import clientConfig from '../../client-config'

const WorkFeature = ({
  title,
  subtitle,
  color,
  backgroundColor,
  links,
  linkColor,
  forceFullWidth,
  featureVisual,
  isLink,
}) => {
  const isFirstExternal = String(links && links.length > 0 ? links[0].url : '/').startsWith('http')
  return title ? (
    <>
      {isLink ? (
        <>
          {!isFirstExternal ? (
            <Link
              to={links && links.length > 0 ? links[0].url : '/'}
              className={styles.featureLink}
            >
              <div className={styles.featureContainer} style={{ backgroundColor, color }}>
                <div className={styles.featureVisual} style={{ backgroundColor }}>
                  {featureVisual !== null && featureVisual !== undefined ? (
                    featureVisual.image ? (
                      <figure
                        style={{
                          width: featureVisual.width === 'full' ? '100%' : '960px',
                          height: '680px',
                          margin: '0 auto',
                        }}
                      >
                        <Img
                          fluid={getFluidGatsbyImage(
                            featureVisual.image.asset._id,
                            {},
                            clientConfig.sanity
                          )}
                          alt={featureVisual.alt}
                          objectFit="cover"
                          style={{ height: '680px' }}
                          imgStyle={{ objectFit: 'cover', height: '680px' }}
                        />
                      </figure>
                    ) : (
                      <div className={styles.videoWrapper}>
                        <div className={styles.video}>
                          <iframe
                            src={featureVisual}
                            frameBorder={0}
                            style={
                              forceFullWidth
                                ? {
                                    height: '100%',
                                    width: '177.77777778vh',
                                    minWidth: '100%',
                                    minHeight: '56.25vw',
                                  }
                                : {
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                  }
                            }
                            title={
                              "Video"
                            }
                          />
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
                <div className={styles.featureCopyContainer}>
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                  <div className={styles.linksContainer}>
                    {links && links.length > 0
                      ? links.map((link) => {
                          const isExternal = String(link.url).startsWith('http')
                          if (
                            typeof window !== 'undefined' &&
                            window.location.pathname.startsWith(link.url)
                          ) {
                            return null
                          } else {
                            return isExternal ? (
                              <a
                                key={link.url}
                                href={link.url}
                                style={{ color: linkColor.hex || 'blue' }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link.title} ▸
                              </a>
                            ) : (
                              <Link key={link.url} to={link.url} style={{ color: linkColor.hex }}>
                                {link.title} ▸
                              </Link>
                            )
                          }
                        })
                      : null}
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <a href={links[0].url}>
              <div className={styles.featureContainer} style={{ backgroundColor, color }}>
                <div className={styles.featureVisual} style={{ backgroundColor }}>
                  {featureVisual !== null && featureVisual !== undefined ? (
                    featureVisual.image ? (
                      <figure
                        style={{
                          width: featureVisual.width === 'full' ? '100%' : '960px',
                          height: '680px',
                          margin: '0 auto',
                        }}
                      >
                        <Img
                          fluid={getFluidGatsbyImage(
                            featureVisual.image.asset._id,
                            {},
                            clientConfig.sanity
                          )}
                          alt={featureVisual.alt}
                          objectFit="cover"
                          style={{ height: '680px' }}
                          imgStyle={{ objectFit: 'cover', height: '680px' }}
                        />
                      </figure>
                    ) : (
                      <div className={styles.videoWrapper}>
                        <div className={styles.video}>
                          <iframe
                            src={featureVisual}
                            frameBorder={0}
                            style={
                              forceFullWidth
                                ? {
                                    height: '100%',
                                    width: '177.77777778vh',
                                    minWidth: '100%',
                                    minHeight: '56.25vw',
                                  }
                                : {
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                  }
                            }
                            title={
                              "Video"
                            }
                          />
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
                <div className={styles.featureCopyContainer}>
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                  <div className={styles.linksContainer}>
                    {links && links.length > 0
                      ? links.map((link) => {
                          const isExternal = String(link.url).startsWith('http')
                          if (
                            typeof window !== 'undefined' &&
                            window.location.pathname.startsWith(link.url)
                          ) {
                            return null
                          } else {
                            return isExternal ? (
                              <a
                                key={link.url}
                                href={link.url}
                                style={{ color: linkColor.hex || 'blue' }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link.title} ▸
                              </a>
                            ) : (
                              <Link key={link.url} to={link.url} style={{ color: linkColor.hex }}>
                                {link.title} ▸
                              </Link>
                            )
                          }
                        })
                      : null}
                  </div>
                </div>
              </div>
            </a>
          )}
        </>
      ) : (
        <div className={styles.featureContainer} style={{ backgroundColor, color }}>
          <div className={styles.featureVisual} style={{ backgroundColor }}>
            {featureVisual !== null && featureVisual !== undefined ? (
              featureVisual.image ? (
                <figure
                  style={{
                    width: featureVisual.width === 'full' ? '100%' : '960px',
                    height: '680px',
                    margin: '0 auto',
                  }}
                >
                  <Img
                    fluid={getFluidGatsbyImage(
                      featureVisual.image.asset._id,
                      {},
                      clientConfig.sanity
                    )}
                    alt={featureVisual.alt}
                    objectFit="cover"
                    style={{ height: '680px' }}
                    imgStyle={{ objectFit: 'cover', height: '680px' }}
                  />
                </figure>
              ) : (
                <div className={styles.videoWrapper}>
                  <div className={styles.video}>
                    <iframe
                      src={featureVisual}
                      frameBorder={0}
                      style={
                        forceFullWidth
                          ? {
                              height: '100%',
                              width: '177.77777778vh',
                              minWidth: '100%',
                              minHeight: '56.25vw',
                            }
                          : {
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                            }
                      }
                      title={
                        "Video"
                      }
                    />
                  </div>
                </div>
              )
            ) : null}
          </div>
          <div className={styles.featureCopyContainer}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <div className={styles.linksContainer}>
              {links && links.length > 0
                ? links.map((link) => {
                    const isExternal = String(link.url).startsWith('http')
                    if (
                      typeof window !== 'undefined' &&
                      window.location.pathname.startsWith(link.url)
                    ) {
                      return null
                    } else {
                      return isExternal ? (
                        <a
                          key={link.url}
                          href={link.url}
                          style={{ color: linkColor.hex || 'blue' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.title} ▸
                        </a>
                      ) : (
                        <Link key={link.url} to={link.url} style={{ color: linkColor.hex }}>
                          {link.title} ▸
                        </Link>
                      )
                    }
                  })
                : null}
            </div>
          </div>
        </div>
      )}
    </>
  ) : null
}

export default WorkFeature
